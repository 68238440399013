<template>
  <th-wrapper
    body-class="p-8 pb-3"
    :collapsable="showScheduledRecurrence"
    :full-width-title="!showScheduledRecurrence"
  >
    <!-- Title -->
    <template #title>
      <title-text
        :title="$t('pages.transaction.scheduled_recurrence.subtitle')"
        :no-data="!showScheduledRecurrence"
      />
    </template>
    <div v-if="showScheduledRecurrence">
      <div class="mb-8 border-b pb-5">
        <single-row-info
          :info="scheduledRecurrence?.jobDurationNumber"
          :tag="
            $t(
              'common.orders.transaction.scheduled_recurrence.duration_number.label'
            )
          "
        />
        <single-row-info
          :info="scheduledRecurrence?.jobDurationUnit"
          :tag="
            $t(
              'common.orders.transaction.scheduled_recurrence.duration_unit.label'
            )
          "
        />
        <single-row-info
          :info="scheduledRecurrence?.jobName"
          :tag="$t('common.orders.transaction.scheduled_recurrence.name.label')"
        />
        <single-row-info
          :info="scheduledRecurrence?.jobStartTime"
          :tag="
            $t(
              'common.orders.transaction.scheduled_recurrence.start_time.label'
            )
          "
        />
        <single-row-info
          :info="scheduledRecurrence?.jobNextExecutionTime"
          :tag="
            $t(
              'common.orders.transaction.scheduled_recurrence.next_execution_time.label'
            )
          "
        />
        <single-row-info
          :info="scheduledRecurrence?.jobEndTime"
          :tag="
            $t('common.orders.transaction.scheduled_recurrence.end_time.label')
          "
        />
        <single-row-info
          :info="scheduledRecurrence?.jobType"
          :tag="$t('common.orders.transaction.scheduled_recurrence.type.label')"
        />
        <single-row-info
          :info="
            contracts[scheduledRecurrence?.paymentRecurrenceContract] ||
            scheduledRecurrence?.paymentRecurrenceContract
          "
          :tag="
            $t(
              'common.orders.transaction.scheduled_recurrence.recurrence_contract.label'
            )
          "
        />
        <single-row-info
          :info="
            sequences[scheduledRecurrence?.paymentRecurrenceSequence] ||
            scheduledRecurrence?.paymentRecurrenceSequence
          "
          :tag="
            $t(
              'common.orders.transaction.scheduled_recurrence.recurrence_sequence.label'
            )
          "
        />
      </div>
      <el-row :gutter="20" class="w-full mb-5">
        <el-col :md="24">
          <span class="u-subtitle">{{
            $t(
              'common.orders.transaction.scheduled_recurrence.execution.subtitle'
            )
          }}</span>
        </el-col>
      </el-row>
      <single-row-info
        :info="execution?.second"
        :tag="
          $t(
            'common.orders.transaction.scheduled_recurrence.execution.second.label'
          )
        "
      />
      <single-row-info
        :info="execution?.minute"
        :tag="
          $t(
            'common.orders.transaction.scheduled_recurrence.execution.minute.label'
          )
        "
      />
      <single-row-info
        :info="execution?.hour"
        :tag="
          $t(
            'common.orders.transaction.scheduled_recurrence.execution.hour.label'
          )
        "
      />
      <single-row-info
        :info="execution?.dayOfTheMonth"
        :tag="
          $t(
            'common.orders.transaction.scheduled_recurrence.execution.day_of_the_month.label'
          )
        "
      />
      <single-row-info
        :info="execution?.month"
        :tag="
          $t(
            'common.orders.transaction.scheduled_recurrence.execution.month.label'
          )
        "
      />
      <single-row-info
        :info="execution?.dayOfTheWeek"
        :tag="
          $t(
            'common.orders.transaction.scheduled_recurrence.execution.day_of_the_week.label'
          )
        "
      />
      <single-row-info
        :info="execution?.year"
        :tag="
          $t(
            'common.orders.transaction.scheduled_recurrence.execution.year.label'
          )
        "
      />
      <single-row-info
        :info="execution?.expression"
        :tag="
          $t(
            'common.orders.transaction.scheduled_recurrence.execution.expression.label'
          )
        "
      />
    </div>
  </th-wrapper>
</template>

<script>
import titleText from '../../components/title-text'
import singleRowInfo from '../../components/single-row-info.vue'
import orderSetup from '../../constants/order'
export default {
  components: {
    titleText,
    singleRowInfo
  },
  props: {
    transaction: {
      type: Object,
      default: () => ({})
    }
  },
  setup() {
    const orderConst = orderSetup.setup()
    const contracts = {}
    orderConst.paymentRecurrenceContracts.forEach((contract) => {
      contracts[contract.value] = contract.label
    })
    const sequences = {}
    orderConst.paymentRecurrenceSequences.forEach((sequence) => {
      sequences[sequence.value] = sequence.label
    })
    return {
      contracts,
      sequences
    }
  },
  computed: {
    scheduledRecurrence() {
      const scheduledRecurrence = {}
      const fieldNames = [
        'jobDurationNumber',
        'jobDurationUnit',
        'jobName',
        'jobStartTime',
        'jobNextExecutionTime',
        'jobEndTime',
        'jobType',
        'paymentRecurrenceContract',
        'paymentRecurrenceSequence',
        'jobExecution'
      ]
      fieldNames.forEach((fieldName) => {
        if (this.transaction[fieldName])
          scheduledRecurrence[fieldName] = this.transaction[fieldName]
      })
      return scheduledRecurrence
    },
    execution() {
      return this.scheduledRecurrence?.jobExecution || {}
    },
    showScheduledRecurrence() {
      return (
        Object.keys(this.execution || {}).length > 0 ||
        Object.keys(this.scheduledRecurrence || {}).length > 0
      )
    }
  }
}
</script>
