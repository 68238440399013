<template>
  <div>
    <single-row-info
      :info="
        $formatCurrency(
          presentationData?.paymentPresentationAmount,
          presentationData?.paymentPresentationCurrency
        )
      "
      :tag="
        $t('common.orders.transaction.payment.presentation_data.amount.label')
      "
    />
    <single-row-info
      :info="presentationData?.paymentPresentationCurrency"
      :tag="
        $t('common.orders.transaction.payment.presentation_data.currency.label')
      "
    />
    <single-row-info
      :info="presentationData?.paymentPresentationFxRate"
      :tag="
        $t(
          'common.orders.transaction.payment.presentation_data.fx_rate_eur.label'
        )
      "
    />
    <single-row-info
      :info="presentationData?.paymentPresentationUsage"
      :tag="
        $t('common.orders.transaction.payment.presentation_data.usage.label')
      "
    />
  </div>
</template>
<script>
import singleRowInfo from '../../components/single-row-info.vue'
export default {
  components: {
    singleRowInfo
  },
  props: {
    presentationData: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
