<template>
  <th-wrapper
    body-class="p-8 pb-3"
    :collapsable="!processingIsEmpty"
    :full-width-title="processingIsEmpty"
  >
    <!-- Title -->
    <template #title>
      <title-text
        :title="$t('pages.transaction.processing.subtitle')"
        :no-data="processingIsEmpty"
      />
    </template>
    <div v-if="!processingIsEmpty" class="pb-4">
      <single-row-info
        :tag="$t('pages.transaction.processing.code')"
        :info="transaction.processingCode"
      />
      <single-row-info
        :tag="$t('pages.transaction.processing.result')"
        :info="transaction.processingResult"
      />

      <single-row-info
        :tag="$t('pages.transaction.processing.secured')"
        :info="transaction.processingSecured"
      />

      <single-row-info
        :tag="$t('pages.transaction.processing.statusCode')"
        :info="transaction.processingStatusCode"
      />

      <single-row-info
        :tag="$t('pages.transaction.processing.statusDescription')"
        :info="transaction.processingStatusDescription"
      />
      <single-row-info
        :tag="$t('pages.transaction.processing.reasonCode')"
        :info="transaction.processingReasonCode"
      />

      <single-row-info
        :tag="$t('pages.transaction.processing.reasonDescription')"
        :info="transaction.processingReasonDescription"
      />

      <single-row-info
        :tag="$t('pages.transaction.processing.returnValueCode')"
        :info="returnValueCode"
      />

      <single-row-info
        :tag="$t('pages.transaction.processing.returnValueDescription')"
        :info="transaction.processingReturnValueDescription"
      />
      <!--chargeback info pending-->
      <single-row-info
        :tag="$t('pages.transaction.processing.confirmationStatus')"
        :info="transaction.processingConfirmationStatus"
      />

      <single-row-info
        :tag="$t('pages.transaction.processing.redirectParameters')"
        :info="transaction.processingRedirectParameters"
      />

      <single-row-info
        :tag="$t('pages.transaction.processing.redirectUrl')"
        :info="transaction.processingRedirectUrl"
      />
    </div>
  </th-wrapper>
</template>

<script>
import titleText from '../../components/title-text'
import singleRowInfo from '../../components/single-row-info.vue'
export default {
  components: {
    titleText,
    singleRowInfo
  },
  props: {
    transaction: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    processingIsEmpty() {
      return Object.keys(this.transaction).length === 0
    },
    returnValueCode() {
      return this.transaction.processingReturnValueCode
        ? JSON.parse(this.transaction.processingReturnValueCode)?.string
        : ''
    }
  }
}
</script>
