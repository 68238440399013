<template>
  <div>
    <single-row-info
      :info="
        $formatCurrency(
          clearingData?.paymentClearingAmountOrigin?.amount,
          clearingData?.paymentClearingCurrency
        )
      "
      :tag="$t('common.orders.transaction.payment.clearing_data.amount.label')"
    />
    <single-row-info
      :info="clearingData?.paymentClearingCurrency"
      :tag="
        $t('common.orders.transaction.payment.clearing_data.currency.label')
      "
    />
    <single-row-info
      :info="clearingData?.paymentClearingDescriptor"
      :tag="
        $t('common.orders.transaction.payment.clearing_data.descriptor.label')
      "
    />
    <single-row-info
      :info="clearingData?.paymentClearingDate"
      :tag="$t('common.orders.transaction.payment.clearing_data.date.label')"
    />
    <single-row-info
      :info="clearingData?.paymentClearingSupport"
      :tag="$t('common.orders.transaction.payment.clearing_data.support.label')"
    />
    <single-row-info
      :info="clearingData?.paymentClearingFxRate"
      :tag="$t('common.orders.transaction.payment.clearing_data.fx_rate.label')"
    />
    <single-row-info
      :info="clearingData?.source"
      :tag="
        $t('common.orders.transaction.payment.clearing_data.fx_source.label')
      "
    />
    <single-row-info
      :info="paymentClearingFxDate"
      :tag="$t('common.orders.transaction.payment.clearing_data.fx_date.label')"
    />
    <single-row-info
      :info="clearingData?.paymentClearingBankName"
      :tag="
        $t('common.orders.transaction.payment.clearing_data.bank_name.label')
      "
    />
  </div>
</template>
<script>
import singleRowInfo from '../../components/single-row-info.vue'
export default {
  components: {
    singleRowInfo
  },
  props: {
    clearingData: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    paymentClearingFxDate() {
      return this.clearingData?.paymentClearingFxDate
        ? this.$date.formatDateTime(this.clearingData?.paymentClearingFxDate)
        : null
    }
  }
}
</script>
