<template>
  <div>
    <el-row :gutter="20" class="py-2 marketplace-header">
      <el-col :md="3">{{ $t('pages.transaction.marketplace.item') }}</el-col>
      <el-col :md="4">{{ $t('pages.transaction.marketplace.channel') }}</el-col>
      <el-col :md="7">
        {{ $t('pages.transaction.marketplace.transaction') }}
      </el-col>
      <el-col :md="3">{{ $t('pages.transaction.marketplace.amount') }}</el-col>
      <el-col :md="4">
        {{ $t('pages.transaction.marketplace.baseAmount') }}
      </el-col>
      <el-col :md="3">{{ $t('pages.transaction.marketplace.usage') }}</el-col>
    </el-row>
    <el-row
      v-for="item in items"
      :key="item.itemId"
      :gutter="20"
      class="py-2 marketplace-data"
    >
      <el-col :md="3">{{ item.itemId }}</el-col>
      <el-col :md="4">{{ item.channel }}</el-col>
      <el-col :md="7">{{ item.transactionId }}</el-col>
      <el-col :md="3">{{ item.amount }}</el-col>
      <el-col :md="4">{{ item.baseAmount }}</el-col>
      <el-col :md="3">{{ item.usage }}</el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    }
  }
}
</script>
<style scoped>
.marketplace-header > * {
  font-family: Unzer Visuelt;
  color: var(--font-color);
  font-size: var(--control-font-size);
  font-weight: var(--label-font-weight);
  letter-spacing: var(--letter-spacing-titles);
}

.marketplace-header,
.marketplace-data {
  border-bottom: 1px solid var(--background-color);
}

.marketplace-data > * {
  font-family: Unzer Visuelt;
  color: var(--font-color);
  font-size: var(--control-font-size);
  line-height: var(--el-line-height);
  letter-spacing: var(--letter-spacing-table);
}
</style>
