<template>
  <div>
    <div class="border-b mb-5">
      <single-row-info
        :tag="$t('common.orders.transaction.payment.account_data.brand.label')"
        has-slot-info
        first-slot-style
      >
        <div class="flex">
          <origin-payment
            :origin="
              accountData?.paymentMethodBrandCode ||
              accountData?.paymentMethodCode
            "
            class="mr-2"
          />
          <span class="place-self-center">{{
            brands[accountData?.paymentMethodBrandCode] ||
            paymentMethods[accountData?.paymentMethodCode] ||
            brands['undefined']
          }}</span>
        </div>
      </single-row-info>
      <single-row-info
        :info="accountData?.accountNumber"
        :tag="
          $t('common.orders.transaction.payment.account_data.card_number.label')
        "
      />
      <single-row-info
        :info="accountData?.accountExpiry"
        :tag="
          $t('common.orders.transaction.payment.account_data.expiry_date.label')
        "
      />
      <single-row-info
        :info="accountData?.accountHolder"
        :tag="
          $t('common.orders.transaction.payment.account_data.card_holder.label')
        "
      />
      <single-row-info
        :tag="
          $t(
            'common.orders.transaction.payment.account_data.issuer_country.label'
          )
        "
        has-slot-info
      >
        <origin-country :origin="accountData?.accountIssuerCountry" />
      </single-row-info>
    </div>
    <div class="border-b mb-5">
      <single-row-info
        :info="accountData?.accountBankName"
        :tag="
          $t('common.orders.transaction.payment.account_data.bank_name.label')
        "
      />
      <single-row-info
        :info="accountData?.accountBank"
        :tag="
          $t('common.orders.transaction.payment.account_data.bank_code.label')
        "
      />
      <single-row-info
        :info="accountData?.accountIban"
        :tag="$t('common.orders.transaction.payment.account_data.iban.label')"
      />
      <single-row-info
        :info="accountData?.accountBic"
        :tag="$t('common.orders.transaction.payment.account_data.bic.label')"
      />
      <single-row-info
        :tag="
          $t(
            'common.orders.transaction.payment.account_data.country_of_bank.label'
          )
        "
        has-slot-info
      >
        <origin-country :origin="accountData?.accountCountry" />
      </single-row-info>
    </div>
    <single-row-info
      :info="accountData?.accountTokenProvider"
      :tag="
        $t(
          'common.orders.transaction.payment.account_data.token_provider.label'
        )
      "
    />
    <single-row-info
      :info="accountData?.accountRegistrationId"
      :tag="
        $t(
          'common.orders.transaction.payment.account_data.registration_id.label'
        )
      "
    />
    <single-row-info
      :info="accountData?.accountIdentification"
      :tag="
        $t(
          'common.orders.transaction.payment.account_data.identification.label'
        )
      "
    />
  </div>
</template>
<script>
import originPayment from '../../components/origin-payment.vue'
import originCountry from '../../components/origin-country'
import orderSetup from '../../constants/order'
import singleRowInfo from '../../components/single-row-info.vue'
export default {
  components: {
    originPayment,
    originCountry,
    singleRowInfo
  },
  props: {
    accountData: {
      type: Object,
      default: () => {}
    }
  },
  setup() {
    const orderConst = orderSetup.setup()
    const brands = {}
    orderConst.paymentMethodBrands.forEach((brand) => {
      brands[brand.value] = brand.label
    })
    const paymentMethods = {}
    orderConst.paymentMethodCodes.forEach((method) => {
      paymentMethods[method.value] = method.label
    })
    return {
      brands,
      paymentMethods
    }
  }
}
</script>
