<template>
  <th-wrapper
    body-class="p-8 pb-3"
    :collapsable="!threeDsIsEmpty"
    :full-width-title="threeDsIsEmpty"
  >
    <!-- Title -->
    <template #title>
      <title-text
        :title="$t('pages.transaction.threeDs.subtitle')"
        :no-data="threeDsIsEmpty"
      />
    </template>
    <div v-if="!threeDsIsEmpty" class="pb-4">
      <single-row-info
        :tag="$t('pages.transaction.threeDs.version')"
        :info="
          versions[threeDS?.paymentThreedsVersion] ||
          threeDS?.paymentThreedsVersion
        "
      />

      <single-row-info
        :tag="$t('pages.transaction.threeDs.status')"
        :info="
          statuses[threeDS?.paymentThreedsStatus] ||
          threeDS?.paymentThreedsStatus
        "
      />

      <single-row-info
        :tag="$t('pages.transaction.threeDs.reason')"
        :info="
          reasons[threeDS?.paymentThreedsReason] ||
          threeDS?.paymentThreedsReason
        "
      />

      <single-row-info
        :tag="$t('pages.transaction.threeDs.xid')"
        :info="threeDS.paymentThreedsXid"
      />

      <single-row-info
        :tag="$t('pages.transaction.threeDs.transactionId')"
        :info="threeDS.paymentThreedsDsTransactionId"
      />

      <single-row-info
        :tag="$t('pages.transaction.threeDs.cavv')"
        :info="threeDS.paymentThreedsDsCavv"
      />

      <single-row-info
        :tag="$t('pages.transaction.threeDs.challengeIndicator')"
        :info="
          indicators[threeDS?.paymentThreedsChallengeIndicator] ||
          threeDS?.paymentThreedsChallengeIndicator
        "
      />

      <single-row-info
        :tag="$t('pages.transaction.threeDs.exemption')"
        :info="
          exemptions[threeDS?.paymentThreedsExemption] ||
          threeDS?.paymentThreedsExemption
        "
      />

      <single-row-info
        :tag="$t('pages.transaction.threeDs.commerceIndicator')"
        :info="
          commerceIndicators[
            threeDS?.paymentThreedsElectronicCommerceIndicator
          ] || threeDS?.paymentThreedsElectronicCommerceIndicator
        "
      />

      <single-row-info
        :tag="$t('pages.transaction.threeDs.liability')"
        :info="
          liabilities[threeDS?.paymentLiability] || threeDS?.paymentLiability
        "
      />
    </div>
  </th-wrapper>
</template>

<script>
import titleText from '../../components/title-text'
import singleRowInfo from '../../components/single-row-info.vue'
import orderSetup from '../../constants/order'
export default {
  components: {
    titleText,
    singleRowInfo
  },
  props: {
    transaction: {
      type: Object,
      default: () => ({})
    }
  },
  setup() {
    const orderConst = orderSetup.setup()
    const versions = {}
    orderConst.paymentThreeDSVersions.forEach((version) => {
      versions[version.value] = version.label
    })
    const statuses = {}
    orderConst.paymentThreeDSStatuses.forEach((status) => {
      statuses[status.value] = status.label
    })
    const reasons = {}
    orderConst.paymentThreeDSReasons.forEach((reason) => {
      reasons[reason.value] = reason.label
    })
    const indicators = {}
    orderConst.paymentThreeDSIndicators.forEach((indicator) => {
      indicators[indicator.value] = indicator.label
    })
    const exemptions = {}
    orderConst.paymentThreeDSExemptions.forEach((exemption) => {
      exemptions[exemption.value] = exemption.label
    })
    const commerceIndicators = {}
    orderConst.paymentThreeDSCommerceIndicators.forEach((indicator) => {
      commerceIndicators[indicator.value] = indicator.label
    })
    const liabilities = {}
    orderConst.paymentThreeDSCommerceLiabilities.forEach((liability) => {
      liabilities[liability.value] = liability.label
    })
    return {
      versions,
      statuses,
      reasons,
      indicators,
      exemptions,
      commerceIndicators,
      liabilities
    }
  },
  data() {
    return {
      fieldNames: [
        'paymentThreedsVersion',
        'paymentThreedsStatus',
        'paymentThreedsReason',
        'paymentThreedsXid',
        'paymentThreedsDsTransactionId',
        'paymentThreedsDsCavv',
        'paymentThreedsChallengeIndicator',
        'paymentThreedsExemption',
        'paymentThreedsElectronicCommerceIndicator',
        'paymentLiability'
      ]
    }
  },
  computed: {
    threeDS() {
      const threeDS = {}
      this.fieldNames.forEach((fieldName) => {
        if (this.transaction[fieldName])
          threeDS[fieldName] = this.transaction[fieldName]
      })
      return threeDS
    },
    threeDsIsEmpty() {
      return Object.keys(this.threeDS).length === 0
    }
  }
}
</script>
