<template>
  <th-wrapper
    body-class="p-8 pb-3"
    :collapsable="showInstalment"
    :full-width-title="!showInstalment"
  >
    <!-- Title -->
    <template #title>
      <title-text
        :title="$t('pages.transaction.instalment.subtitle')"
        :no-data="!showInstalment"
      />
    </template>
    <div v-if="showInstalment">
      <single-row-info
        :info="instalment?.instalmentNumberOfInstalments"
        :tag="
          $t('common.orders.transaction.instalment.number_of_instalments.label')
        "
      />
      <single-row-info
        :info="instalment?.instalmentInterestRate"
        :tag="$t('common.orders.transaction.instalment.interest_rate.label')"
      />
    </div>
  </th-wrapper>
</template>

<script>
import titleText from '../../components/title-text'
import singleRowInfo from '../../components/single-row-info.vue'
export default {
  components: {
    titleText,
    singleRowInfo
  },
  props: {
    instalment: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    showInstalment() {
      return !!(
        this.instalment?.instalmentInterestRate ||
        this.instalment?.instalmentNumberOfInstalments
      )
    }
  }
}
</script>
