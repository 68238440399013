<template>
  <th-page-wrapper v-loading="loading">
    <transaction-general
      ref="general"
      :transaction="transaction"
      class="m-8"
      @scroll-to="scrollTo($event)"
    />
    <transaction-payment ref="payment" :payment="transaction" class="m-8" />
    <transaction-customer ref="customer" v-model="transaction" class="m-8" />
    <transaction-detail ref="detail" :transaction="transaction" class="m-8" />
    <transaction-criteria
      ref="criteria"
      :criteria="transaction?.analysisCriteria"
      class="m-8"
    />
    <transaction-processing
      ref="processing"
      :transaction="transaction"
      class="m-8"
    />
    <transaction-three-ds
      ref="threeDs"
      :transaction="transaction"
      class="m-8"
    />
    <transaction-marketplace
      ref="marketplace"
      :transaction="transaction"
      class="m-8"
    />
    <transaction-insurance
      ref="insurance"
      :transaction="transaction"
      class="m-8"
    />
    <transaction-instalment
      ref="instalment"
      :instalment="transaction"
      class="m-8"
    />
    <transaction-scheduled-recurrence
      ref="scheduled-recurrence"
      :transaction="transaction"
      class="m-8"
    />
  </th-page-wrapper>
</template>

<script>
import TransactionGeneral from './components/transaction-general'
import TransactionCustomer from '../components/order-customer'
import TransactionCriteria from './components/transaction-criteria'
import TransactionProcessing from './components/transaction-processing'
import TransactionThreeDs from './components/transaction-threeDs'
import TransactionMarketplace from './components/transaction-marketplace'
import TransactionInsurance from './components/transaction-insurance'
import TransactionPayment from './components/transaction-payment'
import TransactionDetail from './components/transaction-detail'
import TransactionInstalment from './components/transaction-instalment'
import TransactionScheduledRecurrence from './components/transaction-scheduled-recurrence'
import th from '@tillhub/javascript-sdk'

export default {
  name: 'ReportsOrdersTransactionsDetail',
  metaInfo() {
    return {
      title: this.$t('pages.transactions.title')
    }
  },
  components: {
    TransactionGeneral,
    TransactionCustomer,
    TransactionCriteria,
    TransactionProcessing,
    TransactionThreeDs,
    TransactionMarketplace,
    TransactionInsurance,
    TransactionPayment,
    TransactionDetail,
    TransactionInstalment,
    TransactionScheduledRecurrence
  },
  data() {
    return {
      transaction: {}
    }
  },
  computed: {
    transactionId() {
      return this.$route.params.id
    }
  },
  async mounted() {
    this.fetch(this.transactionId)
  },
  methods: {
    async fetch(id) {
      try {
        this.loading = true
        const { data = {} } = await th.transactionsV3().get(id)
        this.transaction = this.formatTransaction(data)
      } catch (err) {
        const errorMessage = this.isTokenError(err)
          ? this.$t('common.error.token.read.single')
          : this.$t('common.error.action.read.single', {
              resource: this.$t('common.resource.order.singular')
            })
        this.$logException(err, {
          trackError: false,
          message: errorMessage
        })
      } finally {
        this.loading = false
      }
    },
    isTokenError(error) {
      return (
        (error?.response?.status === 400 || error?.response?.status === 401) &&
        error?.response?.data?.error?.includes('invalid')
      )
    },
    formatTransaction(transaction) {
      transaction.paymentMethodBrandCode =
        transaction.paymentMethodBrandCode === 'undefined'
          ? null
          : transaction.paymentMethodBrandCode
      transaction.paymentMethodCode =
        transaction.paymentMethodCode === 'undefined'
          ? null
          : transaction.paymentMethodCode
      return transaction
    },
    scrollTo($event) {
      const element = this.$refs[$event]
      if (element?.$el?.scrollIntoView) {
        element.$el.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest'
        })
      }
    }
  }
}
</script>

<style scoped></style>
