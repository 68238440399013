<template>
  <th-wrapper
    body-class="p-8 pb-3"
    :collapsable="!marketplaceIsEmpty"
    :full-width-title="marketplaceIsEmpty"
  >
    <template #title>
      <title-text
        :title="$t('pages.transaction.marketplace.subtitle')"
        :no-data="marketplaceIsEmpty"
      />
    </template>
    <div v-if="!marketplaceIsEmpty" class="pb-4">
      <el-row :gutter="20" class="mb-8">
        <el-col :md="4">
          <div class="flex flex-col">
            <span class="u-table-section-subtitle">
              {{ $t('pages.transaction.marketplace.baseAmount') }}
            </span>
            <span class="u-table-section-data">
              {{ transaction?.marketplaceBaseAmountOrigin?.amount }}
            </span>
          </div>
        </el-col>
      </el-row>
      <transaction-marketplace-table :items="transaction.marketplaceItems" />
    </div>
  </th-wrapper>
</template>
<script>
import titleText from '../../components/title-text'
import TransactionMarketplaceTable from './transaction-marketplace-table.vue'

export default {
  components: {
    titleText,
    TransactionMarketplaceTable
  },
  props: {
    transaction: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    marketplaceIsEmpty() {
      return !this.transaction?.marketplaceItems?.length
    }
  }
}
</script>
