<template>
  <th-wrapper
    body-class="p-8 pb-3"
    :collapsable="showDetail"
    :full-width-title="!showDetail"
  >
    <!-- Title -->
    <template #title>
      <title-text
        :title="$t('pages.transaction.detail.subtitle')"
        :no-data="!showDetail"
      />
    </template>
    <div v-if="showDetail">
      <div class="mb-8 border-b pb-5">
        <single-row-info
          :info="transactionTypes[transaction?.transactionType]"
          :tag="$t('common.orders.transaction.detail.transaction_type.label')"
        />
        <single-row-info
          :info="transaction?.state"
          :tag="$t('common.orders.transaction.detail.state.label')"
        />
        <single-row-info
          :info="formatDateTime(transaction?.requestTimestamp)"
          :tag="$t('common.orders.transaction.detail.request_timestamp.label')"
        />
        <single-row-info
          :info="transaction?.mode"
          :tag="$t('common.orders.transaction.detail.mode.label')"
        />
        <single-row-info
          :info="transaction?.response"
          :tag="$t('common.orders.transaction.detail.response.label')"
        />
        <single-row-info
          :info="transaction?.source"
          :tag="$t('common.orders.transaction.detail.source.label')"
        />
        <single-row-info
          :info="transaction?.pnpResponseCode"
          :tag="$t('common.orders.transaction.detail.pnp_response_code.label')"
        />
        <single-row-info
          :info="transaction?.pnpResponseMessage"
          :tag="
            $t('common.orders.transaction.detail.pnp_response_message.label')
          "
        />
        <single-row-info
          :info="transaction?.paymentMethodSecured"
          :tag="
            $t('common.orders.transaction.detail.payment_method_secured.label')
          "
        />
        <single-row-info
          :info="transaction?.paymentMethodFactoring"
          :tag="
            $t(
              'common.orders.transaction.detail.payment_method_factoring.label'
            )
          "
        />
      </div>
      <div class="mb-8 border-b pb-5">
        <el-row :gutter="20" class="w-full mb-5">
          <el-col :md="24">
            <span class="u-subtitle">{{
              $t('common.orders.transaction.detail.identification.subtitle')
            }}</span>
          </el-col>
        </el-row>
        <single-row-info
          :info="transaction?.identificationRootId"
          :tag="
            $t('common.orders.transaction.detail.identification.root_id.label')
          "
        />
        <single-row-info
          :info="transaction?.identificationTransactionId"
          :tag="
            $t(
              'common.orders.transaction.detail.identification.transaction_id.label'
            )
          "
        />
        <single-row-info
          :info="transaction?.identificationUniqueId"
          :tag="
            $t(
              'common.orders.transaction.detail.identification.unique_id.label'
            )
          "
        />
        <single-row-info
          :info="transaction?.identificationShortId"
          :tag="
            $t('common.orders.transaction.detail.identification.short_id.label')
          "
        />
        <single-row-info
          :info="transaction?.identificationShopperId"
          :tag="
            $t(
              'common.orders.transaction.detail.identification.shopper_id.label'
            )
          "
        />
        <single-row-info
          :info="transaction?.identificationInvoiceId"
          :tag="
            $t(
              'common.orders.transaction.detail.identification.invoice_id.label'
            )
          "
        />
        <single-row-info
          :info="transaction?.identificationReferenceId"
          :tag="
            $t(
              'common.orders.transaction.detail.identification.reference_id.label'
            )
          "
        />
        <single-row-info
          :info="transaction?.identificationContractId"
          :tag="
            $t(
              'common.orders.transaction.detail.identification.contract_id.label'
            )
          "
        />
        <single-row-info
          :info="transaction?.identificationCreditorId"
          :tag="
            $t(
              'common.orders.transaction.detail.identification.creditor_id.label'
            )
          "
        />
        <single-row-info
          :info="transaction?.identificationPnpTxnId"
          :tag="
            $t(
              'common.orders.transaction.detail.identification.pnp_txn_id.label'
            )
          "
        />
        <single-row-info
          :info="transaction?.identificationPnpTxnId2"
          :tag="
            $t(
              'common.orders.transaction.detail.identification.pnp_txn_id_2.label'
            )
          "
        />
        <single-row-info
          :info="transaction?.identificationPnpId"
          :tag="
            $t('common.orders.transaction.detail.identification.pnp_id.label')
          "
        />
        <single-row-info
          :info="transaction?.identificationPnpName"
          :tag="
            $t('common.orders.transaction.detail.identification.pnp_name.label')
          "
        />
        <single-row-info
          :info="transaction?.identificationCutoverId"
          :tag="
            $t(
              'common.orders.transaction.detail.identification.cutover_id.label'
            )
          "
        />
      </div>
      <div class="mb-8 border-b pb-5">
        <el-row :gutter="20" class="w-full mb-5">
          <el-col :md="24">
            <span class="u-subtitle">{{
              $t('common.orders.transaction.detail.payment.subtitle')
            }}</span>
          </el-col>
        </el-row>
        <single-row-info
          :info="
            $formatCurrency(
              transaction?.paymentDccAmount?.amount,
              transaction?.paymentDccCurrency
            )
          "
          :tag="$t('common.orders.transaction.detail.payment.dcc_amount.label')"
        />
        <single-row-info
          :info="transaction?.paymentDccCurrency"
          :tag="
            $t('common.orders.transaction.detail.payment.dcc_currency.label')
          "
        />
        <single-row-info
          :info="transaction?.paymentTerminalId"
          :tag="
            $t('common.orders.transaction.detail.payment.terminal_id.label')
          "
        />
        <single-row-info
          :info="transaction?.paymentPosEntryMode"
          :tag="
            $t('common.orders.transaction.detail.payment.pos_entry_mode.label')
          "
        />
        <single-row-info
          :info="transaction?.paymentCardAcceptorId"
          :tag="
            $t(
              'common.orders.transaction.detail.payment.card_acceptor_id.label'
            )
          "
        />
        <single-row-info
          :info="transaction?.paymentAuthorizationCode"
          :tag="
            $t(
              'common.orders.transaction.detail.payment.authorization_code.label'
            )
          "
        />
        <single-row-info
          :info="formatDateTime(transaction?.paymentCutoverTimestamp)"
          :tag="
            $t(
              'common.orders.transaction.detail.payment.cutover_timestamp.label'
            )
          "
        />
        <single-row-info
          :info="formatDateTime(transaction?.paymentUploadTimestamp)"
          :tag="
            $t(
              'common.orders.transaction.detail.payment.upload_timestamp.label'
            )
          "
        />
        <single-row-info
          :info="transaction?.paymentSepaType"
          :tag="$t('common.orders.transaction.detail.payment.sepa_type.label')"
        />
        <single-row-info
          :info="transaction?.paymentReversalType"
          :tag="
            $t('common.orders.transaction.detail.payment.reversal_type.label')
          "
        />
      </div>
      <div class="mb-8 border-b pb-5">
        <el-row :gutter="20" class="w-full mb-5">
          <el-col :md="24">
            <span class="u-subtitle">{{
              $t('common.orders.transaction.detail.frontend.subtitle')
            }}</span>
          </el-col>
        </el-row>
        <single-row-info
          :info="transaction?.frontendResponseUrl"
          :tag="
            $t('common.orders.transaction.detail.frontend.response_url.label')
          "
        />
        <single-row-info
          :info="transaction?.frontendSessionId"
          :tag="
            $t('common.orders.transaction.detail.frontend.session_id.label')
          "
        />
        <single-row-info
          :info="transaction?.frontendLanguageId"
          :tag="
            $t('common.orders.transaction.detail.frontend.language_id.label')
          "
        />
      </div>
      <div class="mb-8 border-b pb-5">
        <el-row :gutter="20" class="w-full mb-5">
          <el-col :md="24">
            <span class="u-subtitle">{{
              $t('common.orders.transaction.detail.authentication.subtitle')
            }}</span>
          </el-col>
        </el-row>
        <single-row-info
          :info="transaction?.authenticationResultIndicator"
          :tag="
            $t(
              'common.orders.transaction.detail.authentication.result_indicator.label'
            )
          "
        />
        <single-row-info
          :tag="
            $t(
              'common.orders.transaction.detail.authentication.parameters.label'
            )
          "
          :has-slot-info="hasValue(transaction.authenticationParameters)"
        >
          {{ slotInfoValue(transaction.authenticationParameters) }}
        </single-row-info>
        <div v-if="transaction?.authenticationParameters">
          <div
            v-for="property in formattedParams(
              transaction.authenticationParameters
            )"
            :key="property"
          >
            <single-row-info
              :info="property.value"
              :tag="pruned(property.key)"
              nested-slot
            />
          </div>
        </div>
        <single-row-info
          :info="transaction?.authenticationType"
          :tag="
            $t('common.orders.transaction.detail.authentication.type.label')
          "
        />
      </div>
      <div class="mb-8 border-b pb-5">
        <el-row :gutter="20" class="w-full mb-5">
          <el-col :md="24">
            <span class="u-subtitle">{{
              $t('common.orders.transaction.detail.credential_on_file.subtitle')
            }}</span>
          </el-col>
        </el-row>
        <single-row-info
          :info="transaction?.credentialOnFileInitiation"
          :tag="
            $t(
              'common.orders.transaction.detail.credential_on_file.initiation.label'
            )
          "
        />
        <single-row-info
          :info="transaction?.credentialOnFileBrandTransactionId"
          :tag="
            $t(
              'common.orders.transaction.detail.credential_on_file.brand_transaction_id.label'
            )
          "
        />
      </div>
      <div class="mb-8 border-b pb-5">
        <el-row :gutter="20" class="w-full mb-5">
          <el-col :md="24">
            <span class="u-subtitle">{{
              $t('common.orders.transaction.detail.shipping.subtitle')
            }}</span>
          </el-col>
        </el-row>
        <single-row-info
          :info="transaction?.shippingTrackingId"
          :tag="
            $t('common.orders.transaction.detail.shipping.tracking_id.label')
          "
        />
        <single-row-info
          :info="transaction?.shippingCarrier"
          :tag="$t('common.orders.transaction.detail.shipping.carrier.label')"
        />
      </div>
      <div class="mb-8 border-b pb-5">
        <el-row :gutter="20" class="w-full mb-5">
          <el-col :md="24">
            <span class="u-subtitle">{{
              $t('common.orders.transaction.detail.channel.subtitle')
            }}</span>
          </el-col>
        </el-row>
        <single-row-info
          :info="transaction?.channelId"
          :tag="$t('common.orders.transaction.detail.channel.channel_id.label')"
        />
        <single-row-info
          :info="transaction?.channel"
          :tag="$t('common.orders.transaction.detail.channel.channel.label')"
        />
        <single-row-info
          :info="transaction?.channelName"
          :tag="
            $t('common.orders.transaction.detail.channel.channel_name.label')
          "
        />
      </div>
      <div class="pb-5">
        <el-row :gutter="20" class="w-full mb-5">
          <el-col :md="24">
            <span class="u-subtitle">{{
              $t('common.orders.transaction.detail.merchant.subtitle')
            }}</span>
          </el-col>
        </el-row>
        <single-row-info
          :info="transaction?.merchantUnzerId"
          :tag="$t('common.orders.transaction.detail.merchant.unzer_id.label')"
        />
        <single-row-info
          :info="transaction?.merchantUnzerName"
          :tag="
            $t('common.orders.transaction.detail.merchant.unzer_name.label')
          "
        />
        <single-row-info
          :info="transaction?.merchantId"
          :tag="
            $t('common.orders.transaction.detail.merchant.merchant_id.label')
          "
        />
        <single-row-info
          :info="transaction?.merchantName"
          :tag="
            $t('common.orders.transaction.detail.merchant.merchant_name.label')
          "
        />
      </div>
    </div>
  </th-wrapper>
</template>

<script>
import titleText from '../../components/title-text'
import orderSetup from '../../constants/order'
import singleRowInfo from '../../components/single-row-info.vue'
export default {
  components: {
    titleText,
    singleRowInfo
  },
  props: {
    transaction: {
      type: Object,
      default: () => ({})
    }
  },
  setup() {
    const orderConst = orderSetup.setup()
    const transactionTypes = {}
    orderConst.transactionTypes.forEach((type) => {
      transactionTypes[type.value] = type.label
    })
    return {
      transactionTypes
    }
  },
  computed: {
    showDetail() {
      return true
    }
  },
  methods: {
    formattedParams(params) {
      return Object.entries(params).map(([key, value]) => ({
        key,
        value
      }))
    },
    pruned(prop) {
      const stringWithSpaces = prop.replace(/_/g, ' ')
      const words = stringWithSpaces.split(' ')
      const capitalizedProp = words.map((word, index) => {
        if (word.length === 0) {
          return word
        }
        if (index === 0) {
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        }
        // any word that contains a number or ID should be uppercased
        if (/\d/.test(word) || word.toUpperCase() === 'ID') {
          return word.toUpperCase()
        }
        return word.toLowerCase()
      })
      return capitalizedProp.join(' ')
    },
    hasValue(property) {
      const exists = property !== undefined && property !== null
      return property
    },
    slotInfoValue(property) {
      if (!this.hasValue(property)) {
        return this.$t('common.orders.not_defined')
      }
    },
    formatDateTime(date) {
      if (!date) return
      return this.$date.formatDateTime(date)
    }
  }
}
</script>
