<template>
  <th-wrapper body-class="p-8 pb-3" full-width-title navigate-back-button>
    <!-- Title -->
    <template #title>
      <div class="w-3/4 ml-2 flex">
        <span class="u-title-section"
          >{{ $t('pages.transaction.subtitle') }}
          {{ transaction?.identificationUniqueId }}</span
        >
        <status-text :status="transaction?.state" />
      </div>
      <span class="w-1/5 text-right u-title-section mb-2">
        {{
          $formatCurrency(
            transaction?.paymentPresentationAmount,
            transaction?.paymentPresentationCurrency
          ) || notDefined
        }}
      </span>
    </template>
    <el-row :gutter="20" class="mb-8">
      <el-col :md="5">
        <div class="flex flex-col">
          <span class="u-table-section-subtitle">{{
            $t('pages.transactions.detail.headers.timestamp')
          }}</span>
          <span class="u-table-section-data">{{ requestTimestamp }}</span>
        </div>
      </el-col>
      <el-col :md="4">
        <div class="flex flex-col">
          <span class="u-table-section-subtitle">{{
            $t('pages.transactions.detail.headers.transaction_type')
          }}</span>
          <span class="u-table-section-data">{{
            transactionTypes[transaction?.transactionType] ||
            transaction?.transactionType ||
            notDefined
          }}</span>
        </div>
      </el-col>
      <el-col :md="6">
        <div class="flex flex-col">
          <span class="u-table-section-subtitle w-48">{{
            $t('pages.transactions.detail.headers.payment_methods')
          }}</span>
          <div
            class="u-table-section-data text-th-primary cursor-pointer flex"
            @click="$emit('scroll-to', 'payment')"
          >
            <origin-payment
              :origin="
                transaction?.paymentMethodBrandCode ||
                transaction?.paymentMethodCode
              "
              class="mr-2"
            />
            <span class="place-self-center"
              >{{
                brands[transaction?.paymentMethodBrandCode] ||
                paymentMethods[transaction?.paymentMethodCode]
              }}
              {{ transaction?.accountNumber }}</span
            >
          </div>
        </div>
      </el-col>
      <el-col :md="4">
        <div class="flex flex-col">
          <span class="u-table-section-subtitle">{{
            $t('pages.transactions.detail.headers.customer')
          }}</span>
          <span
            v-if="customerName"
            class="u-table-section-data text-th-primary cursor-pointer"
            @click="$emit('scroll-to', 'customer')"
            >{{ customerName }}</span
          >
          <span v-else class="u-table-section-data text-th-primary-gray">
            {{ notDefined }}
          </span>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="20" class="mb-8">
      <el-col :md="8">
        <div class="flex flex-col">
          <span class="u-table-section-subtitle">{{
            $t('pages.transactions.detail.headers.sales_channel')
          }}</span>
          <span
            class="u-table-section-data"
            :class="{ 'text-th-primary-gray': !transaction.channelName }"
          >
            {{ transaction?.channelName || notDefined }}
          </span>
        </div>
      </el-col>
      <el-col :md="5">
        <div class="flex flex-col">
          <span class="u-table-section-subtitle">{{
            $t('pages.transactions.detail.headers.basket')
          }}</span>
          <span
            v-if="transaction?.basketId"
            class="u-table-section-data text-th-primary cursor-pointer flex"
            @click="$emit('scroll-to', 'basket')"
            >{{ transaction?.basketId }}</span
          >
          <span v-else class="u-table-section-data text-th-primary-gray">
            {{ notDefined }}
          </span>
        </div>
      </el-col>
    </el-row>
  </th-wrapper>
</template>

<script>
import orderSetup from '../../constants/order'
import statusText from '../../components/status-text'
import originPayment from '../../components/origin-payment'
export default {
  components: {
    statusText,
    originPayment
  },
  props: {
    transaction: {
      type: Object,
      default: () => ({})
    }
  },

  setup() {
    const orderConst = orderSetup.setup()
    const brands = {}
    const paymentMethods = {}
    const transactionTypes = {}
    orderConst.paymentMethodBrands.forEach((brand) => {
      brands[brand.value] = brand.label
    })
    orderConst.paymentMethodCodes.forEach((method) => {
      paymentMethods[method.value] = method.label
    })
    orderConst.transactionTypes.forEach((type) => {
      transactionTypes[type.value] = type.label
    })
    return {
      brands,
      paymentMethods,
      transactionTypes
    }
  },

  computed: {
    customerName() {
      return this.transaction?.customer?.displayName
    },
    requestTimestamp() {
      return this.transaction?.requestTimestamp
        ? this.$date.formatDateTime(this.transaction?.requestTimestamp)
        : this.notDefined
    },
    notDefined() {
      return this.$t('common.orders.not_defined')
    }
  }
}
</script>
