<template>
  <th-wrapper
    body-class="p-8 pb-3"
    :collapsable="!paymentIsEmpty"
    :full-width-title="paymentIsEmpty"
  >
    <!-- Title -->
    <template #title>
      <title-text
        :title="$t('pages.transaction.payment.subtitle')"
        :no-data="paymentIsEmpty"
      />
    </template>
    <div v-if="!paymentIsEmpty">
      <div class="flex flex-col mb-8 border-b pb-5">
        <span class="u-subtitle">{{
          $t('pages.transactions.detail.payment.header.account_data')
        }}</span>
        <transaction-payment-account-data :account-data="payment" />
      </div>
      <div class="flex flex-col mb-8 border-b pb-5">
        <span class="u-subtitle">{{
          $t('pages.transactions.detail.payment.header.presentation_data')
        }}</span>
        <transaction-payment-presentation-data :presentation-data="payment" />
      </div>
      <div class="flex flex-col mb-8">
        <span class="u-subtitle">{{
          $t('pages.transactions.detail.payment.header.payment_clearing_data')
        }}</span>
        <transaction-payment-clearing-data :clearing-data="payment" />
      </div>
    </div>
  </th-wrapper>
</template>

<script>
import titleText from '../../components/title-text'
import transactionPaymentAccountData from './transaction-payment-account-data.vue'
import transactionPaymentPresentationData from './transaction-payment-presentation-data.vue'
import transactionPaymentClearingData from './transaction-payment-clearing-data.vue'
export default {
  components: {
    titleText,
    transactionPaymentAccountData,
    transactionPaymentPresentationData,
    transactionPaymentClearingData
  },
  props: {
    payment: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    paymentIsEmpty() {
      return Object.keys(this.payment || {}).length === 0
    }
  }
}
</script>
