<template>
  <th-wrapper
    body-class="p-8 pb-3"
    :collapsable="!insuranceIsEmpty"
    :full-width-title="insuranceIsEmpty"
  >
    <!-- Title -->
    <template #title>
      <title-text
        :title="$t('pages.transaction.insurance.subtitle')"
        :no-data="insuranceIsEmpty"
      />
    </template>
    <div v-if="!insuranceIsEmpty" class="pb-4">
      <single-row-info
        :tag="$t('pages.transaction.insurance.id')"
        :info="transaction.insuranceId"
      />

      <single-row-info
        :tag="$t('pages.transaction.insurance.inquiryId')"
        :info="transaction.insuranceInquiryId"
      />

      <single-row-info
        :tag="$t('pages.transaction.insurance.finalizeDate')"
        :info="transaction.insuranceFinalizeDate"
      />

      <single-row-info
        :tag="$t('pages.transaction.insurance.dueDate')"
        :info="transaction.insuranceDueDate"
      />

      <single-row-info
        :tag="$t('pages.transaction.insurance.activationDate')"
        :info="transaction.insuranceActivationDate"
      />

      <single-row-info
        :tag="$t('pages.transaction.insurance.firstDate')"
        :info="transaction.insuranceFirstReminderDate"
      />

      <single-row-info
        :tag="$t('pages.transaction.insurance.secondDate')"
        :info="transaction.insuranceSecondReminderDate"
      />

      <single-row-info
        :tag="$t('pages.transaction.insurance.thirdDate')"
        :info="transaction.insuranceThirdReminderDate"
      />

      <single-row-info
        :tag="$t('pages.transaction.insurance.fourthDate')"
        :info="transaction.insuranceFourthReminderDate"
      />

      <single-row-info
        :tag="$t('pages.transaction.insurance.paymentTargetDate')"
        :info="transaction.insurancePaymentTargetDate"
      />

      <single-row-info
        :tag="$t('pages.transaction.insurance.realizationDeadline')"
        :info="transaction.insuranceRealizationDeadlineDate"
      />

      <single-row-info
        :tag="$t('pages.transaction.insurance.reservationDate')"
        :info="transaction.insuranceReservationDate"
      />

      <single-row-info
        :tag="$t('pages.transaction.insurance.errorCode')"
        :info="transaction.insuranceErrorCode"
      />

      <single-row-info
        :tag="$t('pages.transaction.insurance.errorMessage')"
        :info="transaction.insuranceErrorMessage"
      />

      <single-row-info
        :tag="$t('pages.transaction.insurance.provider')"
        :info="transaction.insuranceProvider"
      />
    </div>
  </th-wrapper>
</template>

<script>
import titleText from '../../components/title-text'
import singleRowInfo from '../../components/single-row-info.vue'
export default {
  components: {
    titleText,
    singleRowInfo
  },
  props: {
    transaction: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      fieldNames: [
        'insuranceId',
        'insuranceInquiryId',
        'insuranceFinalizeDate',
        'insuranceDueDate',
        'insuranceActivationDate',
        'insuranceFirstReminderDate',
        'insuranceSecondReminderDate',
        'insuranceThirdReminderDate',
        'insuranceFourthReminderDate',
        'insurancePaymentTargetDate',
        'insuranceRealizationDeadlineDate',
        'insuranceReservationDate',
        'insuranceErrorCode',
        'insuranceErrorMessage',
        'insuranceProvider'
      ]
    }
  },
  computed: {
    insurance() {
      const insurance = {}
      this.fieldNames.forEach((fieldName) => {
        if (this.transaction[fieldName])
          insurance[fieldName] = this.transaction[fieldName]
      })
      return insurance
    },
    insuranceIsEmpty() {
      return Object.keys(this.insurance).length === 0
    }
  }
}
</script>
