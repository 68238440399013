<template>
  <th-wrapper
    body-class="p-8 pb-3"
    :collapsable="!criteriaIsEmpty"
    :full-width-title="criteriaIsEmpty"
  >
    <!-- Title -->
    <template #title>
      <title-text
        :title="$t('pages.transaction.criteria.subtitle')"
        :no-data="criteriaIsEmpty"
      />
    </template>
    <div v-if="!criteriaIsEmpty" class="pb-4">
      <single-row-info
        v-for="(item, index) in criteria"
        :key="index"
        :tag="index"
        :info="item"
      />
    </div>
  </th-wrapper>
</template>

<script>
import titleText from '../../components/title-text'
import singleRowInfo from '../../components/single-row-info.vue'
export default {
  components: {
    titleText,
    singleRowInfo
  },
  props: {
    criteria: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    criteriaIsEmpty() {
      return !this.criteria || Object.keys(this.criteria).length === 0
    }
  }
}
</script>
